define('mlevents/models/day', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		"event": _emberData.default.belongsTo('event', {
			async: true
		}),
		"date": (0, _attr.default)('string'),
		"times": _emberData.default.hasMany('time', {
			async: true
		}),
		"timeSorting": ['time_start'],
		"sortedTimes": Ember.computed.sort('times', 'timeSorting')
	});
});