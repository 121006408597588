define('mlevents/models/prospect', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		"event": _emberData.default.belongsTo('event', {
			async: true
		}),
		"laner": _emberData.default.belongsTo('laner', {
			async: true
		}),
		"slots": _emberData.default.hasMany('slot', {
			async: true
		}),
		"removeProspect": Ember.computed('slots.@each.status', function (test) {

			//console.log('work!!!!!');

			var this_ = this;

			if (this_.get('slots').filterBy('status', 'declined').length + this_.get('slots').filterBy('status', 'released').length === this_.get('slots').get('length')) {
				return true;
			} else {
				return false;
			}

			/*
   var slots = this_.get('slots').then(function(the_slots){
   		if( (the_slots.filterBy('status','declined').length + the_slots.filterBy('status','released').length) === the_slots.get('length') ){
   		console.log('true');
   		return true;
   	} else {
   		console.log('false');
   		return false;
   	}
   	});
   */

			// /return slots;

			//return DS.PromiseObject.create({slots});
		}),
		"photos": (0, _attr.default)(),
		"laner-bio": (0, _attr.default)('string'),
		"note": (0, _attr.default)('string'),
		"bio": (0, _attr.default)('boolean'),
		"last": (0, _attr.default)('boolean'),
		"email": (0, _attr.default)('boolean'),
		"phone": (0, _attr.default)('boolean'),
		"sizes": (0, _attr.default)('boolean'),
		"site": (0, _attr.default)('boolean'),
		"reel": (0, _attr.default)('boolean'),
		"status": (0, _attr.default)('string'),
		// "statusShow": function(){
		// 	if(this.get('status') === 'available' || this.get('status') === 'booked'){
		// 		return true;
		// 	}
		// }.property('status'),
		"hidden": (0, _attr.default)('boolean'),
		"order": (0, _attr.default)('number')

	});
});