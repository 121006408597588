define('mlevents/models/laner', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		"name-first": (0, _attr.default)('string'),
		"name-last": (0, _attr.default)('string'),
		"nickname": (0, _attr.default)('string'),
		"pronouns": (0, _attr.default)(),
		"pronouns_other": (0, _attr.default)('string'),
		"phone": (0, _attr.default)('string'),
		"email": (0, _attr.default)('string'),
		"photos": (0, _attr.default)(),
		"sex": (0, _attr.default)('string'),
		"age-range": (0, _attr.default)('string'),
		"height": (0, _attr.default)(),
		"height-format": function () {
			if (this.get('height.ft') !== undefined && this.get('height.in') !== undefined) {
				return this.get('height.ft') + '\' ' + this.get('height.in') + '"';
			} else {
				return '';
			}
		}.property('height'),
		"weight": (0, _attr.default)('number'),
		"measurement": (0, _attr.default)(),
		"shoe": (0, _attr.default)('number'),
		"jacket": (0, _attr.default)('string'),
		"shirt": (0, _attr.default)(),
		"pants": (0, _attr.default)(),
		"pant": (0, _attr.default)('number'),
		"coat": (0, _attr.default)('string'),
		"tshirt": (0, _attr.default)(),
		"dress": (0, _attr.default)(),
		"website": (0, _attr.default)('string'),
		"reel": (0, _attr.default)('string'),
		"bio": (0, _attr.default)('string')
	});
});