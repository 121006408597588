define('mlevents/models/event', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		"client": _emberData.default.belongsTo('client', {
			async: true
		}),
		"e_name": (0, _attr.default)('string'),
		"e_location": (0, _attr.default)('string'),
		"e_date_start": (0, _attr.default)('string'),
		"e_date_end": (0, _attr.default)('string'),
		"prospects": _emberData.default.hasMany('prospect', {
			async: true
		}),
		"sortedProspectsBy": ['order'],
		"sortedProspects": Ember.computed.sort('prospects', 'sortedProspectsBy'),
		"days": _emberData.default.hasMany('day', {
			async: true
		}),
		"sortedDaysBy": ['date'],
		"sortedDays": Ember.computed.sort('days', 'sortedDaysBy')
		// "totalProspects": Ember.computed(function(){
		// 	return this.get('sortedProspects.length');
		// })
	});
});