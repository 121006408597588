define('mlevents/models/slot', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		"time": _emberData.default.belongsTo('time', {
			async: true
		}),
		"laner": _emberData.default.belongsTo('laner', {
			async: true
		}),
		"prospect": _emberData.default.belongsTo('prospect', {
			async: true
		}),
		"status": (0, _attr.default)('string'),
		"statusShow": function () {
			if (this.get('status') === 'booked') {
				return true;
			}
		}.property('status'),
		"order": (0, _attr.default)('number')
	});
});