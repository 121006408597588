define('mlevents/models/client', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		"c_company": (0, _attr.default)('string'),
		"c_events": DS.hasMany('event', {
			async: true
		}),
		"type": (0, _attr.default)('string')
	});
});