define('mlevents/controllers/event', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		filterAll: true,
		filterAllClass: 'filter-on',
		currentEventsClass: '',
		todaysDate: moment().format('YYYY-MM-DD'),
		clearDays: function clearDays() {
			this.store.peekAll('day').forEach(function (this_day) {
				this_day.set('metaFilter', null);
			});
		},
		actions: {
			filterDay: function filterDay(which_day) {

				if (which_day === 'all') {
					this.clearDays();
					this.set('filterAll', true);
					this.set('filterAllClass', 'filter-on');
				} else {
					this.set('filterAll', false);
					this.set('filterAllClass', null);

					this.clearDays();

					which_day.set('metaFilter', 'filter-on');
					this.set('the_day', which_day.get('date'));
				}
			},
			currentEvents: function currentEvents() {
				this.toggleProperty('currentEventsClass', 'filter-on');
				this.set('todaysDate', moment().format('YYYY-MM-DD'));
				this.send('filterDay', 'all');
			}
		}
	});
});