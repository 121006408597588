define('mlevents/routes/event', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		session: Ember.inject.service(),
		beforeModel: function beforeModel() {

			var this_ = this;

			return this_.get('session').fetch().catch(function () {});
		},
		model: function model(params) {

			var this_ = this;

			if (this_.get('session')) {

				var prospect_recs = this_.get('store').findRecord('event', params.event_id).then(function (the_event_rec) {
					return the_event_rec.get('prospects');
				}).catch(function (e) {
					return null;
				});

				var slots_recs = prospect_recs.then(function (prospect_recs) {
					return Ember.RSVP.all(prospect_recs.getEach('slots'));
				}).catch(function (e) {
					return null;
				});

				return Ember.RSVP.hash({
					prospects: prospect_recs,
					slots: slots_recs
				}).then(function (modelHash) {
					return this_.get('store').findRecord('event', params.event_id);
				}).catch(function (e) {
					//alert ('could not find the event :(');
					return null;
				});
			}
		},

		actions: {
			login: function login() {

				var this_ = this;

				this_.get('session').open('firebase', {
					'provider': 'password',
					'email': atob('Y2xpZW50QG11c3RhcmRsYW5lLmNvbQ=='),
					'password': atob('bEltaVQzZC1hQ0Nlc3Nfc3RyZWVULTd0SF9f')
				}).then(function () {
					setTimeout(function () {
						location.reload(true);
					}, 125);
				});
			},
			logout: function logout() {

				// var this_ = this;

				// this_.store.unloadAll();
				// setTimeout(function(){ 
				// 	this_.get('session').close(); 
				// }, 2000);

			}
		}
	});
});