define('mlevents/models/time', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		"day": _emberData.default.belongsTo('day', {
			async: true
		}),
		"time_start": (0, _attr.default)('string'),
		"time_end": (0, _attr.default)('string'),
		"slots": _emberData.default.hasMany('slot', {
			async: true
		}),
		"sortedSlotsBy": ['order'],
		"sortedSlots": Ember.computed.sort('slots', 'sortedSlotsBy')

	});
});